

















































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import {
  format, isAfter, isBefore, isWithinInterval,
} from 'date-fns';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import Community from '@/models/graphql/Community';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import FileResource from '@/models/graphql/FileResource';
import { mixins } from 'vue-class-component';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import Size from '@/utils/enums/Size';
import BaseCardWidget from '@/components/cards/BaseCardWidget.vue';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';

@Component({
  components: {
    FontAwesomeComponent,
    PillWidget,
    AvatarSoloWidget,
    ButtonIconComponent,
  },
  inheritAttrs: false,
})
export default class LiveContentCard extends mixins(BreakpointWrapper, BaseCardWidget) {
  @Prop({
    required: true,
    default: '',
  })
  readonly uid!: string;

  @Prop({
    required: true,
    default: 0,
  })
  readonly id!: number;

  @Prop({
    required: true,
    default: '',
  })
  readonly name!: string;

  @Prop({
    required: false,
    default: null,
  })
  readonly bannerFileResource!: FileResource;

  @Prop({
    required: true,
    default: '',
  })
  readonly startTime!: string;

  @Prop({
    required: true,
    default: '',
  })
  readonly endTime!: string;

  @Prop({ default: null })
  readonly cardRoute!: string;

  @Getter
  community!: Community;

  @Getter
  featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  @State
  private selectedTzAbbreviation!: string;

  private FileResourceHelper = FileResourceHelper;

  private Size = Size;

  private sessionDate = '';

  private bannerError = false;

  private get noImage(): boolean {
    return !this.bannerFileResource;
  }

  private get handleBaseUrl(): string | null {
    if (this.cardRoute) {
      let r = this.cardRoute;
      const matches = this.cardRoute.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '')
            .trim();
          r = r.replaceAll(m, this.$props[prop]);
          r = r.replaceAll(m, this.$route.params[prop]);
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${this.uid}`;
      }
      return `${r}/${this.uid}`;
    }
    return null;
  }

  private get sessionState(): string {
    const today = DateTimeHelper.utcToZonedTimeDate(
      DateTimeHelper.getCurrentDateTime(),
      this.selectedTzName,
    );
    const startTime = DateTimeHelper.utcToZonedTimeDate(
      `${this.startTime}Z`,
      this.selectedTzName,
    );
    const endTime = DateTimeHelper.utcToZonedTimeDate(
      `${this.endTime}Z`,
      this.selectedTzName,
    );
    if (this.startTime
        && this.endTime
        && isBefore(startTime, endTime)
        && isWithinInterval(today, {
          start: startTime,
          end: endTime,
        })) {
      return 'live';
    }
    if (this.startTime && isBefore(today, startTime)) {
      return 'soon';
    }
    if (this.endTime && isAfter(today, endTime)) {
      return 'archived';
    }
    return '';
  }

  mounted(): void {
    this.setSessionDate();
  }

  @Watch('isEventTimeZone')
  private setSessionDate(): void {
    const dateFormat = `${this.$t('app.date.monthDayFull')}
    ${this.$t('app.date.dateTimeAtSeparator')}
    ${this.$t('app.date.defaultTimeFormat')}`;
    const timeZoneAbbreviation = `${!this.featureByKey(FeatureKeys.COMMUNITY_TIMEZONE_FEATURE)
    || !(this.featureByKey(FeatureKeys.COMMUNITY_TIMEZONE_FEATURE) as CommunityFeature).enabled
      ? `(${this.selectedTzAbbreviation})`
      : ''}`;
    this.sessionDate = '';
    let date = '';
    if (this.startTime) {
      const startTime = DateTimeHelper.utcToZonedTimeDate(
        `${this.startTime}Z`,
        this.selectedTzName,
      );
      date = `${format(startTime, dateFormat, { locale: this.dateLocale })}`;
    }

    if (date) {
      this.sessionDate = `${date} ${timeZoneAbbreviation}`;
    }
  }
}
